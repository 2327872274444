import { z } from 'zod'

type PhoneFormat = {
  pattern: RegExp;
  transform: (match: string) => string;
}

const PHONE_FORMATS: PhoneFormat[] = [
  {
    // Format: +33612345678
    pattern: /^\+33[67]\d{8}$/,
    transform: number => number
  },
  {
    // Format: 33612345678
    pattern: /^33[67]\d{8}$/,
    transform: number => `+${number}`
  },
  {
    // Format: 0612345678
    pattern: /^0[67]\d{8}$/,
    transform: number => `+33${number.slice(1)}`
  },
  {
    // Format: 612345678
    pattern: /^[67]\d{8}$/,
    transform: number => `+33${number}`
  }
];

export function normalizePhoneNumber(phone: string): string {
  const cleaned = phone.replace(/[^\d+]/g, '');

  if (!cleaned) return '';

  const format = PHONE_FORMATS.find(({ pattern }) => pattern.test(cleaned));
  return format ? format.transform(cleaned) : '';
}

export const phoneInputSchema = z.string()
  .regex(/^(\+33|33|0)?[67]\d{8}$/, "Numéro de téléphone invalide")
  .or(z.literal(''))
  .transform((val) => normalizePhoneNumber(val));
